'use strict';

angular.module('enervexSalesappApp').factory('Design', function ($resource) {
	return $resource('/api/accounts/:accountId/jobs/:jobId/designs/:id/:versions/:version/:calculation/:bom/:promote/:export/:import', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		},
		fans: {
			method: 'GET',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: 'fans'
			}
		},
		versions: {
			method: 'GET',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@designId',
				versions: 'versions'
			}
		},
		version: {
			method: 'GET',
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@designId',
				versions: 'versions',
				version: '@version'
			}
		},
		calculation: {
			method: 'POST',
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@id',
				calculation: 'calculation'
			}
		},
		bom: {
			method: 'POST',
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@id',
				bom: 'bom'
			}
		},
		promoteVersion: {
			method: 'POST',
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@designId',
				versions: 'versions',
				version: '@version',
				promote: 'promote'
			}
		},
		import: {
			method: 'POST',
			timeout: 340000,
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			},
			params:{
				accountId: '@accountId',
				jobId: '@jobId',
				import: "import"
			}
		},
		importItems: {
			method: 'POST',
			timeout: 340000,
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			},
			params:{
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@designId',
				import: "import"
			}
		}
	});
});
